// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:eb63f867-03df-432e-a434-3b510328b1de",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XuLyS5NW3",
    "aws_user_pools_web_client_id": "5v2g75etfpgr33lpkpou9j5dnb",
    "oauth": {},
    "aws_content_delivery_bucket": "quicksight-embed-20190730193216-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dc3ee3stsv6l2.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://hata6kpntfhovexojz2dacin4m.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
